import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    name: 'notFound',
    path: '/:path(.*)+',
    redirect: {
      name: 'home',
    },
  },
  {
    name: 'home',
    path: '/',
    component: () => import('./view/home'),
    meta: {
      title: '慈云法语-首页',
    },
  },
  {
    name: 'index',
    path: '/',
    component: () => import('./view/home/index'),
    meta: {
      title: '慈云法语-首页',
    },
  },
  {
    name: 'search',
    path: '/search',
    component: () => import('@/view/home/search'),
    meta: {
      title: '慈云法语-搜索',
    },
  },
  {
    name: 'app',
    path: '/app',
    component: () => import('@/view/home/app'),
    meta: {
      title: '慈云法雨APP',
    },
  },
  {
    name: 'redirect',
    path: '/u/:id',
    component: () => import('@/view/redirect/index'),
    meta: {
      title: '跳转',
    },
  },
  {
    name: 'Video',
    path: '/video',
    redirect: '/video/list',
    meta: {
      title: '影音',
    },
    children: [
      {
        path: 'list',
        name: 'VideoList',
        component: () => import('@/view/video/index'),
        meta: { title: '专辑列表' },
        children: [
          {
            path: ':time',
            name: 'VideoList',
            component: () => import('@/view/video/index'),
            meta: { title: '专辑列表' }
          },
        ]
      },
      {
        path: 'down/:model/:aid',
        name: 'VideoDown',
        component: () => import('@/view/video/down'),
        meta: { title: '下载页面' },
        children: [
          {
            path: ':id',
            name: 'VideoDown',
            component: () => import('@/view/video/down'),
            meta: { title: '下载页面' },
            children: [
              {
                path: ':time',
                name: 'VideoDown',
                component: () => import('@/view/video/down'),
                meta: { title: '下载页面' },
              }
            ]
          }
        ],
      },
      {
        path: 'player/:model/:aid',
        name: 'VideoPlayer',
        component: () => import('@/view/video/player'),
        meta: { title: '播放器' },
        children: [
          {
            path: ':id',
            name: 'VideoPlayer',
            component: () => import('@/view/video/player'),
            meta: { title: '播放器' },
            children: [
              {
                path: ':time',
                name: 'VideoPlayer',
                component: () => import('@/view/video/player'),
                meta: { title: '播放器' },
              }
            ]
          }
        ],
      },
    ]
  },
  {
    name: 'Article',
    path: '/article',
    redirect: '/article/list/0',
    meta: {
      title: '资讯',
    },
    children: [
      {
        path: 'list/:aid',
        name: 'ArticleList',
        component: () => import('@/view/article/index'),
        meta: { title: '列表' }
      },
      {
        path: 'detail/:id',
        name: 'ArticleDetail',
        component: () => import('@/view/article/detail'),
        meta: { title: '详情' },
        children: [
          {
            path: ':time',
            name: 'ArticleDetail',
            component: () => import('@/view/article/detail'),
            meta: { title: '详情' },
          },
        ]
      },
      {
        path: 'timetable',
        name: 'TimeTable',
        component: () => import('@/view/article/timetable'),
        meta: { title: '详情' },
        children: [
          {
            path: ':time',
            name: 'TimeTable',
            component: () => import('@/view/article/timetable'),
            meta: { title: '详情' },
          },
        ]
      },
    ]
  }
];

const router = createRouter({
  mode: "history",
  routes,
  history: createWebHistory(),
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export { router };
