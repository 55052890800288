export default {
  name: 'Home',

  data() {
    return {
      url: 'm.liuhejing.cc'
    };
  },

  mounted() {// this.$dialog.alert({
    //   allowHtml:true,
    //   messageAlign:"left",
    //   confirmButtonText:'关 闭',
    //   title:'温馨提示！',
    //   message: '<h1>m.liuhejing.cc</h1><br/>为了您播放顺畅，请复制以上网址，使用浏览器播放。为避免无法播放，请尽量不要分享至朋友圈，感恩，阿弥陀佛！',
    // });
  },

  methods: {},
  metaInfo: {
    title: '慈云法语',
    meta: [{
      charset: 'utf-8'
    }, {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'
    }]
  }
};