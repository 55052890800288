export const public_v1 = "https://a.liuhejing.cc/static/v1/"
export const api_url = "https://api.liuhejing.cc/"
// export const video_url = "/"
export const video_image_url = "/"
//export const video_url = "/"
export const video_url = "https://s.liuhejing.cc/"
export const download_url = "https://d.liuhejing.cc/"
export const audio_url = "https://s.liuhejing.cc/"
//export const audio_url = "/"
export const download_audio_url = "https://s.liuhejing.cc/"
// export const video_image_url = "https://video.cibei.org/"
export const videoPageSize = 100;
//清空客户端缓存
export function clearStorage() {
    localStorage.removeItem('uid');
    localStorage.removeItem('nick_name');
    localStorage.removeItem('icon');
    localStorage.removeItem('token');
    return 1;
}

export function getUrlKey(name) {
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null
}
export function getDate() {
    var date = new Date();
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

//依據編號重構音頻地址
export function getVideoNumUrl(host, num, ext) {
    var url = "";
    if (num != null) {
        var _num = num.split("-");
        url = host + _num[0] + "/" + _num[0] + "-" + _num[1] + "/";
        if (ext == "m3u8") {
            url = url + num + "/";
        }
        url = url + num + "." + ext;
    }
    return url;
}

export function changetime(value) {
    var secondTime = parseInt(value);// 秒
    var minuteTime = 0;// 分
    var hourTime = 0;// 小时
    if (secondTime > 60) {//如果秒数大于60，将秒数转换成整数
        //获取分钟，除以60取整数，得到整数分钟
        minuteTime = parseInt(secondTime / 60);
        //获取秒数，秒数取佘，得到整数秒数
        secondTime = parseInt(secondTime % 60);
        //如果分钟大于60，将分钟转换成小时
        if (minuteTime > 60) {
            //获取小时，获取分钟除以60，得到整数小时
            hourTime = parseInt(minuteTime / 60);
            //获取小时后取佘的分，获取分钟除以60取佘的分
            minuteTime = parseInt(minuteTime % 60);
        }
    }
    var time = parseInt(secondTime);
    if (hourTime) {
        time = hourTime + ":";
    }
    if (secondTime < 10) {
        time = "0" + parseInt(secondTime);
    }
    if (minuteTime < 10) {
        time = "0" + parseInt(minuteTime) + ":" + time;
    } else {
        time = parseInt(minuteTime) + ":" + time;
    }
    return time;
}

//判断时间差
export function timeCha(time) {
    var timestamp = Date.parse(new Date());
    return timestamp - time;
}

export function getPageView(total) {
    var pageSize = videoPageSize;
    var pageList = [];
    for (var i = 0; i < (total / pageSize); i++) {
        var last = pageSize * (i + 1);
        if (last > total) {
            last = total;
        }
        pageList[i] = { title: pageSize * i + 1 + '-' + last };
    }
    return pageList;
}

export function getDevice() {

    var u = navigator.userAgent;
    let device = '';
    //if(u.indexOf('AppleWebKit') > -1){
    //  device = 'AppleWebKit';
    //}  
    //if(u.indexOf("chrome") > -1){
    // device = 'chrome';
    //}
    //if(u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1){
    //  device = 'Gecko';
    //}
    //if(!!u.match(/AppleWebKit.*Mobile.*/) || !!u.match(/AppleWebKit/)){
    //  device = 'mobile';
    //}
    if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) {
        device = 'Android';
    }
    if (u.indexOf('iPhone') > -1) {
        device = 'iPhone';
    }
    if (u.indexOf('iPad') > -1) {
        device = 'iPad';
    }
    if (u.indexOf('Safari') == -1) {
        device = 'Safari';
    }
    console.log(device);
    return device;
}

export function IsWeixinOrAlipay() {
    var ua = window.navigator.userAgent.toLowerCase();
    //判断是不是微信
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return 13;
    }
    //判断是不是支付宝
    if (ua.match(/AlipayClient/i) == 'alipayclient') {
        return 12;
    }
    //哪个都不是
    return 0;
}
// 判断是在安卓还是ios打开
export function isAndriodOrIos() {
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/iphone/i) == 'iphone') {
        return 1;
    }
    return 0;
}